import React from 'react';
import './App.scss';
import HeaderComponent from '../Components/HeaderComponent';
import HomeComponent from  '../Components/HomeComponent';
import NotFoundComponent from '../Components/NotFoundComponent';
import AboutComponent from '../Components/AboutComponent';
import WorkComponent from '../Components/WorkComponent';
import KnowledgeComponent from '../Components/KnowledgeComponent';
import ContactComponent from '../Components/ContactComponent';
import BlogComponent from '../Components/BlogComponent';
import SingleBlogs from '../Components/SingleBlogs';
import SingleWork from '../Components/SingleWork';
import TermsPage from '../Components/TermsPage';
import PrivacyPage from '../Components/PrivacyPage';
import Footer from '../Components/Footer';
import CareersComponent from '../Components/CareersComponent';
import BriefComponent from '../Components/BriefComponent';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';



function App() {
  return (
     <Router>
      <div className="App">
        <HeaderComponent/>
        <div>
           <Switch>
              <Route exact path='/' component={HomeComponent}></Route>
              <Route exact path='/work' component={WorkComponent}></Route>
              <Route exact path="/work/:workId" component={SingleWork}/>
              <Route exact path='/services' component={KnowledgeComponent}></Route>
              <Route exact path='/careers' component={CareersComponent}></Route>
              <Route exact path='/blog' component={BlogComponent}></Route>
              <Route exact path="/blog/:blogId" component={SingleBlogs}/>
              <Route exact path='/contact' component={ContactComponent}></Route>
              <Route exact path='/brief' component={BriefComponent}></Route>
              <Route exact path='/terms-conditions' component={TermsPage}></Route>
              <Route exact path='/privacy-policy' component={PrivacyPage}></Route>

              <Route component={NotFoundComponent}></Route>
            </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
