/*
 Body Content Component
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Tilt from 'react-tilt'


import './Blogs.scss';

//plugins
import NumberItem from '../NumberItem';

// Articles.
import articles from '../../articles/blogs.js';

// Constants.
const MAX_RESULTS_TO_SHOW = 100;

/*
  BlogPreview.
*/

const BlogPreview = ({ article }) => {
  return (
          
        <div key={article.id} style={{ backgroundImage: `url('${article.image_main}')` }} className="blog-view">
          <Link to={`/blog/${article.id}`} title={article.name} aria-label={article.exerpt}>
          <span className="filter"></span>
          <span className="init-filter"></span>
          <span className="filter-hover"></span>
          <span className="blogs-content-preview">
          <span className="filter-up"></span>
            <span className="blog-content-flex-margin">
              <h3>{article.name}</h3>
              <h6 className="author-blog-article">{article.author}</h6>
              <h6 className="author-job-title">{article.jobtitle}</h6>
              <h6 className="date-blog-article">{article.date}</h6>
              <p className="exerpt"><ReactMarkdown source={article.exerpt}/></p>
            </span>
           </span>
        </Link>
       </div>
        
       );
};
/*
  Counts words in a string (case insensitive).
*/

function wordCount(str, words) {
  const lc = str.toLowerCase();
  return words.filter(word => lc.includes(word)).length;
}

/*
  Helper function to score an article using a search.
*/

function searchScore(article, words) {

  let score = 0;

  // Keyword score.

  if (article.keywords) {
    score += wordCount(article.keywords, words);
  }

  // Name score.

  if (article.name) {
    score += wordCount(article.name, words);
  }

  // Description score.

  if (article.description) {
    score += wordCount(article.description, words);
  }

  // author score
  if(article.author){
    score += wordCount(article.author, words);
  }
  // markdown
  if(article.markdown){
    score += wordCount(article.markdown, words);
  }

  return score;
}



export default class Blogs extends React.Component {



    constructor(props) {

    super(props);

    // State.

     this.state = {
      inputValue: '',
      loading: false,

      results: articles.slice(0, MAX_RESULTS_TO_SHOW),
  };
  this._timer = null;

   

  }

   componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer = () => {
    if (this._timer != null) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  handleKeyDown = (e) => {

    // If "Enter" is pressed, skip the timer and
    // immediately find the results.

    if (e.key === 'Enter') {
      e.preventDefault();

      this.stopTimer();
      this.refreshResults();
    }
  }
 handleInputChange = (e) => {

    this.setState({ inputValue: e.target.value, loading: true });

    // Restart the timer for refreshing results.

    this.stopTimer();
    this._timer = setTimeout(this.refreshResults, 300);

    

  }



  refreshResults = () => {

    // Get array of keywords from the search.

    const words = this.state.inputValue.toLowerCase().match(/\w+/g) || [];

    // If there is no input, just show the first N articles.

    if (words.length === 0) {
      this.setState({
        loading: false,
        results: articles.slice(0, MAX_RESULTS_TO_SHOW),
      });
      return;
    }

    // Filter results by search keywords.

    const results = articles
      .map(article => ({
        article: article,
        score: searchScore(article, words),
      }))
      .filter(obj => obj.score > 0)
      .sort((a, b) => b.score - a.score)
      .slice(0, MAX_RESULTS_TO_SHOW)
      .map(obj => obj.article);

    this.setState({ loading: false, results });
  }

  searchClick() {
   console.log('hello')
  }



  render(){

    const { inputValue, results, loading } = this.state;




   return (

   
 
      <div className="blogs-wrapper animate-in">
       <div className="input-search-wrapper">
         <div>
            <span></span><label for="blog-search-input">Articles</label>
        
            <div className="blogs-text-wrapper">
              <p>We have curated a collection of content to reference a better way to approach our <strong>digital world</strong>. <br/><br/> <strong>Have a browse below</strong></p>
            </div> 
            <label for="blog-search-input" className="search-label" style={{top:'37px'}}>
              Filter Articles
            </label>        
            <input 
              id="blog-search-input"
              className="search-blogs animate-in"
              type="text" 
              placeholder="Search"
              value={this.state.inputValue}
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              maxLength="256"
              onClick={this.searchClick.bind(this)}
              />
          </div>

        </div>
        <div className="cases-wrapper animate-in">
        <NumberItem
         number="01"
         left="0"
         margin="0px 0px 0px 55px"
         top="85px"
         />

        {
          results.length > 0 &&
          <div className="blogs-flex-wrapper animate-in">
            {
              results.map(article =>
                
                  <BlogPreview
                    article={article}
                    key={article.id}
                    />
                
              )
            }
          </div>
        }


        {
          results.length === 0 && !loading && inputValue.length > 0 &&
          <div className="no-results-wrapper">
            <p>There was no match for <h6 style={{display:'inline',color:'#F44336',fontSize:'22px'}}>'{inputValue}'</h6>. Please try another search.
            <br/>
            <span className="no-results-contact-info" style={{position:'relative',fontSize:'14px'}}>Can't find what you're looking for? Contact me <Link to="/contact" className="no-result-link">HERE</Link> or call <a href="tel:0430173838" className="no-result-link">0430173838</a></span>
            </p>
          </div>
        }
        </div>
        
          
      </div>
        
    );
  }
}


