import React, { useRef, useState } from 'react';
import './ContactForm.scss';
import phoneIcon from '../../media/images/phone.svg';
import emailIcon from '../../media/images/email.svg';


const ContactForm = ({intro, form, email, phone, additional}) => {
  const formRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    fetch("https://script.google.com/macros/s/AKfycbyCXnL2h5shhSr3uMCNc_k2elx9JJnbO_Cl3JPx1IYtNxEPxNERkmMQffRClx8E-Zw8-g/exec", {
      method: 'POST',
      body: new FormData(formRef.current),
    })
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        setLoader(false);
        setSuccess(true);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="contact-form-wrapper">
      <div className="contact-text-wrapper">
        <p className="guarantee" style={{textTransform:'uppercase',fontSize:'14px !important'}}>We guarantee to outmatch any quote from any local competitor!</p>
        <p className="review" style={{paddingBottom:'15px',borderBottom: '1px solid rgba(0,0,0,0.1)'}}>Cick <a className="review" href="https://www.google.com/maps/place/Layercayk/@-32.03362,115.79309,17z/data=!3m1!4b1!4m6!3m5!1s0x2a32a3caeeceb42b:0x735c3b2b8bc9a69b!8m2!3d-32.03362!4d115.79309!16s%2Fg%2F11w1gm06nv?entry=ttu" target="_blank" rel="noreferrer">Here</a> to Leave us a review!</p>
        <p className="contact-intro-text" style={{ marginBottom: '5px', lineHeight: '130%' }}>
          {intro}
        </p>
        <div className="contact-direct">
          {phone ? 
          <p className="flex-contact">
            <img src={phoneIcon} alt="Call us"/>
            <a href="tel:{phone}" className="outbound-contact">
              {phone}
            </a>
          </p>
          : ""}
          <p className="flex-contact">
            <img src={emailIcon} alt="Email us"/>
            <a href="mailto:{email}" className="outbound-contact">
              {email}
            </a>
          </p>
        </div>
        {additional}
      </div>
      {form ? 
      <form className="boilerform animate-in" method="post" ref={formRef}>
        <fieldset className="c-form">
          <div className="c-form__row">
            <div>
              <span className="span-inline"></span>
              <label htmlFor="name" className="c-label span-inline">Name*</label>
              <input type="text" name="name" id="name" required="required" />
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="company" className="c-label">Company</label>
              <input type="text" name="company" id="company" />
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="email" className="c-label">E-Mail*</label>
              <input type="email" name="email" id="email" required="required" />
            </div>
          </div>
          <div className="c-form__row">
            <div>
              <span className="span-inline"></span>
              <label htmlFor="phone" className="c-label">Phone Number</label>
              <input type="text" name="phone" id="phone" />
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="budget" className="c-label">Budget*</label>
              <input type="text" name="budget" id="budget" />
            </div>
          </div>
          <div className="c-form__row">
            <span className="span-inline"></span>
            <label htmlFor="message" className="c-label">Project Description*</label>
            <textarea name="message" id="message" className="c-input-field c-input-field--multiline" rows="10"></textarea>
          </div>
          <div className="c-form__row flex flex-v-h-center">
            <button className="c-button" type="submit" onClick={handleSubmit}>Submit</button>
            <span className={loader ? "loader active" : "loader"}></span>
            {success ? <p className="success-message"><span>Thank you - someone from our team will be in touch shortly!</span></p> : ""}
          </div>
        </fieldset>
      </form>

      : ""}
      
    </div>
  );
};

export default ContactForm;
