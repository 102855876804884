/*
Partners Component
*/

import React from 'react';
import './Partners.scss';
import Marquee from "react-fast-marquee";

import laravel from '../../media/images/laravel.svg';
import google from '../../media/images/google-logo.svg.webp';
import figma from '../../media/images/figma-logo.png';
import cpanel from '../../media/images/cpanel-logo.svg';
import aws from '../../media/images/aws-logo.svg';
import adobe from '../../media/images/adobe-logo.svg';
import wordpress from '../../media/images/wordpress-logo.svg';
import shopify from '../../media/images/shopify-logo.svg';
import acf from '../../media/images/acf-logo.png';
import react from '../../media/images/react-logo.png';
import nextjs from '../../media/images/nextjs-logo.png';
import vercel from '../../media/images/vercel.png';

const partners = [
  {
    name: 'Laravel',
    logo: laravel,
    link: 'https://laravel.com/',
    class: 'laravel'
  },
  {
    name: 'Google',
    logo: google,
    link: 'https://google.com',
    class: 'google'
  },
  {
    name: 'Figma',
    logo: figma,
    link: 'https://www.figma.com/',
    class: 'figma'
  },
  {
    name: 'cpanel',
    logo: cpanel,
    link: 'https://cpanel.com',
    class: 'cpanel'
  },
  {
    name: 'Amazon web services',
    logo: aws,
    link: 'https://aws.com',
    class: 'aws'
  },
  {
    name: 'Next.js',
    logo: nextjs,
    link: 'https://nextjs.org/',
    class: 'next'
  },
  {
    name: 'Adobe',
    logo: adobe,
    link: 'https://adobe.com',
    class: 'adobe'
  },
   {
    name: 'Shopify',
    logo: shopify,
    link: 'https://shopify/',
    class: 'shopify'
  },
  {
    name: 'Wordpress',
    logo: wordpress,
    link: 'https://wordpresss.com',
    class: 'Wordpress'
  },
  {
    name: 'Advanced Custom Fields',
    logo: acf,
    link: 'https://www.advancedcustomfields.com/',
    class: 'acf'
  },
  {
    name: 'React.js',
    logo: react,
    link: 'https://react.dev/',
    class: 'react'
  },
  {
    name: 'Vercel',
    logo: vercel,
    link: 'https://vercel.com',
    class: 'vercel'
  }
]

export default function Partners() {
  return (
    <section className="partners">
      <Marquee>
       {partners.map((partner, index) => (
          <a href={partner.link} key="index" target="_blank" rel="noreferrer">
            <img src={partner.logo} alt={partner.link} className={partner.class}/>
          </a>
        ))}
        </Marquee>
    </section>
  );
}
