/*
 Services Component
*/


import React, { Component } from 'react'; 
import {Helmet} from "react-helmet";
import TransitionHeader from './../TransitionHeader'; 

import MinySubHeading from '../MinySubHeading';
import ContactAction from './../ContactAction';
import FooterActionNavigation from './../FooterActionNavigation';
import CaseStudiesContent from './../CaseStudiesContent';

import octopusOne from '../../media/images/the-octopus.jpeg';
import octopusTwo from '../../media/images/octopus-blue.jpeg';
// style variables
import variables from '../../config_var/config_var.js';

export default class WorkComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }

  componentDidMount() {
    window.scrollTo(0, 0);

   

  }


  render(){

 


  return (
     <div className="services-component-wrapper">
     <Helmet>
        <title>Work - Where creativity comes to work. - Layercayk</title>
        <meta name="description" content="Where creativity comes to work. Through creative solutions we take ideas and make them tangible."/>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://layercayk.com/work" />
        <meta property="og:title" content="Layercayk - Where creativity comes to work." />
        <meta property="og:description" content="Through creative solutions I take ideas and make them tangible." />
        <meta property="og:image" content="/static/media/octopus-blue.920137de.jpeg" />
        <link rel="canonical" href="https://layercayk.com/work" />
      </Helmet>

        <TransitionHeader
          title="Where creativity comes to work"
          subtitle="Through creative solutions we take ideas and make them tangible."
          backgroundTitle="Work"
          minySubHeading="My Work"
          minyleft="-64px"


        />

        <CaseStudiesContent
          
        />

        <FooterActionNavigation 
          backgroundone={octopusOne}
          backgroundtwo={octopusTwo}
          linkone="/blog"
          headingone="Blog"
          headingonetext="Keep up to date with current digital trends and more.."
          linktwo="/services"
          headingtwo="Services"
          headingtwotext="Crafting digital experiences to help brands grow."
        />
        <ContactAction />


      </div>
    );
  }
}


