/*
 Contact Component
*/


import React, { Component } from 'react'; 
import {Helmet} from "react-helmet";
import TransitionHeader from './../TransitionHeader'; 

import BriefForm from './../BriefForm';
import FooterActionNavigation from './../FooterActionNavigation';
import octopusOne from '../../media/images/the-octopus.jpeg';
import octopusTwo from '../../media/images/octopus-blue.jpeg';

import './BriefComponent.scss';

export default class BriefComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }

  componentDidMount() {
    window.scrollTo(0, 0);

  }


  render(){

 


  return (
     <div className="brief-component-wrapper">

      <Helmet>
        <title>Start your brief - Layercayk</title>
        <meta name="description" content="Fill in our initial project kick off brief."/>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://layercayk.com/contact" />
        <meta property="og:title" content="Contact. - Layercayk" />
        <meta property="og:description" content="Fill in our initial project kick off brief." />
        <meta property="og:image" content="/static/media/octopus-blue.920137de.jpeg" />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://layercayk.com/contact" />
      </Helmet>

        <TransitionHeader
          title="Submit your project brief"
          subtitle="The more information you provide, the better the solution we can offer."
          backgroundTitle="Contact"
          minySubHeading="Contact"
          minyleft="-64px"


        />
        <BriefForm
          intro={<span>Please take your time to fill out the form with as much information as possible.<br/><br/> This effort will set the stage for a seamless and successful start to creating your project.</span>}
          form
        />
   
        <FooterActionNavigation 
          backgroundone={octopusOne}
          backgroundtwo={octopusTwo}
          borderBottom="0px solid"
          linkone="/work"
          headingone="Work"
          headingonetext="Through creative solutions we take ideas and make them tangible."
          linktwo="/services"
          headingtwo="Services"
          headingtwotext="Crafting digital experiences to help brands grow."
        />
         
      </div>
    );
  }
}


