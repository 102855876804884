import React, { useRef, useState } from 'react';
import './BriefForm.scss';

const BriefForm = ({intro, form}) => {
  const formRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    fetch("https://script.google.com/macros/s/AKfycbxt6pPXW2nkJxEwygJBNQ-KJZieNPctxHfRjzwt9Oq2_814z-_wfOlgsuKx5uItWBns/exec", {
      method: 'POST',
      body: new FormData(formRef.current),
    })
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        setLoader(false);
        setSuccess(true);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="contact-form-wrapper">
      <div className="contact-text-wrapper">
        <p className="contact-intro-text" style={{ marginBottom: '5px', lineHeight: '130%' }}>
          {intro}
        </p>
      </div>
      {form ? 
      <form className="boilerform animate-in brief-form" method="post" ref={formRef}>
        <fieldset className="c-form">
        <h3 style={{textAlign:'left', width: '100%'}}><span>Project Overview</span></h3>
          <div className="c-form__row">
            <div>
              <span className="span-inline"></span>
              <label htmlFor="name" className="c-label span-inline">Full Name*</label>
              <input type="text" name="name" id="name" required="required" placeholder="Your first and last name"/>
            </div>
             <div>
              <span className="span-inline"></span>
              <label htmlFor="name" className="c-label span-inline">Email*</label>
              <input type="text" name="email" id="email" required="required" placeholder="example@example.com"/>
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="name" className="c-label span-inline">Company*</label>
              <input type="text" name="company" id="company" placeholder="Company name"/>
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="purpose" className="c-label">Primary purpose of the website?</label>
              <input type="text" name="purpose" id="purpose" placeholder="Information sharing, e-commerce, portfolio, blog, etc."/>
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="website" className="c-label">Have an existing website, or is this a new project?</label>
              <input type="email" name="website" id="website" placeholder="If existing, what do you like/dislike about it?"/>
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="target" className="c-label">Who is your target audience?</label>
              <input type="email" name="target" id="target" placeholder="Age group, gender, interests, profession, etc."/>
            </div>
          </div>
          <div className="c-form__row">
            <h3 style={{textAlign:'left', width: '100%'}}><span>Content and Features</span></h3>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="features" className="c-label">What key features do you want on your website?</label>
              <textarea type="text" name="features" id="features" placeholder="Examples: contact forms, e-commerce functionality, blog, gallery, search functionality, etc."></textarea>
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="content" className="c-label">Have existing content, or need content creation?</label>
              <textarea type="text" name="content" id="content" placeholder="Text, images, videos, etc."></textarea>
            </div>
            <div>
              <span className="span-inline"></span>
              <label htmlFor="cms" className="c-label">Will you need a Content Management System (CMS)?</label>
              <textarea type="text" name="cms" id="cms" placeholder="If yes, do you have a preference (e.g., WordPress, Joomla, custom CMS)?"></textarea>
            </div>
          </div>
          <div className="c-form__row">
             <h3 style={{textAlign:'left', width: '100%'}}><span>Design and User Experience</span></h3>
             <div>
                <span className="span-inline"></span>
                <label htmlFor="guidelines" className="c-label">Any design preferences or branding guidelines?</label>
                <textarea type="text" name="guidelines" id="guidelines" placeholder="Colors, fonts, logos, style guides, etc."></textarea>
             </div>
             <div>
                <span className="span-inline"></span>
                <label htmlFor="admire" className="c-label">Any websites you admire and would like to emulate?</label>
                <textarea type="text" name="admire" id="admire" placeholder="Functionality, aesthetics, user experience, etc."></textarea>
             </div>
             <div>
                <span className="span-inline"></span>
                <label htmlFor="ux" className="c-label">What type of user experience (UX) are you aiming for?</label>
                <textarea type="text" name="ux" id="ux" placeholder="User flow, ease of navigation, interactivity, etc."></textarea>
             </div>
          </div>
          <div className="c-form__row">
            <h3 style={{textAlign:'left', width: '100%'}}><span>Technical Requirements</span></h3>
            <div>
                <span className="span-inline"></span>
                <label htmlFor="techstack" className="c-label">Have a preferred technology stack or platform?</label>
                <textarea type="text" name="techstack" id="techstack" placeholder="Specific programming languages, frameworks, hosting providers, etc."></textarea>
             </div>
             <div>
                <span className="span-inline"></span>
                <label htmlFor="integrations" className="c-label">Any third-party integrations required?</label>
                <textarea type="text" name="integrations" id="integrations" placeholder="Examples: payment gateways, CRM systems, social media, analytics tools, etc."></textarea>
             </div>
             <div>
                <span className="span-inline"></span>
                <label htmlFor="multilanguage" className="c-label">Will the website need to support multiple languages?</label>
                <textarea type="text" name="multilanguage" id="multilanguage"></textarea>
             </div>
          </div>
          <div className="c-form__row">
              <h3 style={{textAlign:'left', width: '100%'}}><span>Budget and Timeline</span></h3>
              <div>
                 <span className="span-inline"></span>
                <label htmlFor="project_budget" className="c-label">What is your budget for this project?</label>
                <textarea type="text" name="project_budget" id="project_budget" placeholder="This helps in understanding the scope and scale of the project." ></textarea>
              </div>
              <div>
                 <span className="span-inline"></span>
                  <label htmlFor="timeline" className="c-label">What is your desired timeline for completion?</label>
                  <textarea type="text" name="timeline" id="timeline" placeholder="Key milestones, launch date, etc."></textarea>
              </div>
          </div>
           <div className="c-form__row">
              <h3 style={{textAlign:'left', width: '100%'}}><span>SEO and Marketing</span></h3>
              <div>
                 <span className="span-inline"></span>
                  <label htmlFor="seo" className="c-label">Is SEO (Search Engine Optimization) important for you?</label>
                  <textarea type="text" name="seo" id="seo" placeholder="Key milestones, launch date, etc."></textarea>
              </div>
              <div>
                 <span className="span-inline"></span>
                  <label htmlFor="marketing" className="c-label">Need any marketing or promotional features?</label>
                  <textarea type="text" name="marketing" id="marketing" placeholder="Email signups, social media integration, landing pages, etc."></textarea>
              </div>
           </div>
           <div className="c-form__row">
              <h3 style={{textAlign:'left', width: '100%'}}><span>Maintenance and Updates</span></h3>
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="maintain" className="c-label">How do you plan to maintain the website after launch?</label>
                  <textarea type="text" name="maintain" id="maintain" placeholder="In-house team, third-party service, need for ongoing support from the developer."></textarea>
              </div>
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="featureupdates" className="c-label">How often do you anticipate needing updates or new features?</label>
                  <textarea type="text" name="featureupdates" id="featureupdates"></textarea>
              </div>
           </div>
           <div className="c-form__row">
              <h3 style={{textAlign:'left', width: '100%'}}><span>Security and Compliance</span></h3>
               <div>
                  <span className="span-inline"></span>
                  <label htmlFor="security" className="c-label">Any specific security requirements or compliance needs?</label>
                  <textarea type="text" name="security" id="security" placeholder="Data protection, GDPR compliance, SSL certificates, etc."></textarea>
              </div>
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="useroauth" className="c-label">Need any specific user authentication or authorization features?</label>
                  <textarea type="text" name="useroauth" id="useroauth" placeholder="User accounts, roles, permissions, etc."></textarea>
              </div>
          </div>
          <div className="c-form__row">
              <h3 style={{textAlign:'left', width: '100%'}}><span>Analytics and Performance</span></h3>
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="analytics" className="c-label">Need analytics and tracking set up?</label>
                  <textarea type="text" name="analytics" id="analytics" placeholder="Google Analytics, heatmaps, user behavior tracking, etc."></textarea>
              </div>  
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="security" className="c-label">What performance metrics are important to you?</label>
                  <textarea type="text" name="performance" id="performance" placeholder="Page load time, uptime, responsiveness, etc."></textarea>
              </div>  
          </div>
          <div className="c-form__row">
              <h3 style={{textAlign:'left', width: '100%'}}><span>Other Considerations</span></h3>
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="accessibility" className="c-label">Are there any specific accessibility requirements?</label>
                  <textarea type="text" name="accessibility" id="accessibility" placeholder="Ensuring the site is usable for people with disabilities."></textarea>
              </div>  
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="mobiledesktop" className="c-label">Do you anticipate your users to be on mobile or desktop?</label>
                  <textarea type="text" name="mobiledesktop" id="mobiledesktop"></textarea>
              </div>  
              <div>
                  <span className="span-inline"></span>
                  <label htmlFor="other" className="c-label">Any other special requests or considerations?</label>
                  <textarea type="text" name="other" id="other"/>
              </div>  
          </div>
          <div className="c-form__row flex flex-v-h-center">
            <button className="c-button" type="submit" onClick={handleSubmit}>Submit</button>
            <span className={loader ? "loader active" : "loader"}></span>
            {success ? <p className="success-message"><span>Thank you - someone from our team will be in touch shortly!</span></p> : ""}
          </div>
        </fieldset>
      </form>

      : ""}
      
    </div>
  );
};

export default BriefForm;
