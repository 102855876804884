/*
 Services Component
*/

import React from 'react';
import './ServiceOffering.scss';
import productDesign from '../../media/images/product-design.svg';
import uiDesign from '../../media/images/ui-design.svg';
import appDesign from '../../media/images/app-development.svg';
import systemDesign from '../../media/images/system-integrations.svg';
import marketing from '../../media/images/marketing.svg';
import hosting from '../../media/images/hosting.svg';

export default function ServiceOffering() {
  return (
    <section>
      <section className="case-studies-content-wrapper animate-in">
       <span className='miny-sub-heading-wrapper initial-transition-header animate-in animate-show'>
        <span></span><span>Our services</span>
      </span>
        <div className="case-studie-text-wrapper">
          <p>Underpinned by <strong>proven processes</strong> and <strong>robust technology</strong>, we develop <strong>scalable digital products</strong> and <strong>experiences</strong> that your customers will love.</p>
        </div>
      </section>
      <section className="service-offering animate-in">
          <div className="col-50 card">
          <img src={appDesign} alt="Application Development"/>
          <h3>Enterprise web and mobile app development</h3>
          <p>At Layercayk, we craft exceptional digital experiences designed to guide your users seamlessly toward your business objectives, delivering measurable results and a strong return on investment (ROI).</p>
          <p>Specializing in enterprise-level websites and mobile apps, we combine deep technical expertise, a dedicated team, and proven processes to take on even the most complex and demanding projects. </p>
          <p>Whether you're scaling operations or launching new digital initiatives, we’re here to bring your vision to life with precision and innovation.</p>
        </div>
            <div className="col-50 card">
          <img src={marketing} alt="marketing"/>
          <h3>Marketing Solutions & Consultation</h3>
          <p>We offer comprehensive marketing solutions designed to elevate your brand and drive business growth.</p>
          <p>Our services encompass Social Media Marketing (SMM), Search Engine Marketing (SEM), and Email Marketing,
            each tailored to meet your unique needs.</p>
          <p>Our Email Marketing services ensure your messages reach the right audience with personalized, compelling
            content that drives conversions. Partner with us to leverage the power of these marketing channels and
            achieve your business goals.</p>
        </div>
         <div className="col-50 card">
          <img src={productDesign} alt="Product Design"/>
          <h3>Service and product design</h3>
          <p>Creating new digital services and products is crucial for staying competitive.</p>
          <p>We help consult your team design and build these services step-by-step, ensuring a positive design process
            to generate new revenue and provide value to your customers. Our guidance secures your team can
            innovate and create products that make a real difference.</p></div>
        <div className="col-50 card">
          <img src={uiDesign} alt="UI Design"/>
          <h3>User and digital experience design</h3>
          <p>A great user experience connects what your users need and want with your business goals.</p>
          <p>We help you achieve this by using data, user research, prototyping, and user testing to create digital
            experiences that are engaging and memorable, encouraging users to take action.</p>
        </div>

      
        <div className="col-50 card">
          <img src={systemDesign} alt="System Integrations"/>
          <h3>System integrations</h3>
          <p>Creating top level digital experiences often requires complex integrations with third-party systems.</p>
          <p>That's why you need a team with the expertise and confidence to deliver. Our team has extensive experience
            in developing some of the most intricate integrations, including CRM, mapping, ticketing, and events
            systems, among others.</p>
        </div>
        <div className="col-50 card">

          <img src={hosting} alt="hosting"/>
          <h3>Dedicated & Managed Hosting</h3>
          <p>We specialize in offering dedicated managed hosting plans on AWS EC2, tailored to meet the unique needs of
            your business.</p>
          <p>Our managed hosting services provide a robust and scalable infrastructure, ensuring high performance and
            reliability.</p>
          <p>We handle all aspects of server management, including security, monitoring, and web maintenance, so you can
            focus on your core business activities.</p>
        </div>
      </section>
    </section>
  );
}
