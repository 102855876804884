/*
 Blog Posts Home Component
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import './BlogPostsHome.scss';

//plugins
import Tilt from 'react-tilt';
import NumberItem from '../NumberItem';


// Articles.
import articles from '../../articles/blogs.js';
export default class BlogPostsHome extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

    

  }



  divScrolled(){

    let item = this.getBoundingClientRect().top;
    console.log(item);
  }





  render(){

    const {

      subinfo,
      backgroundSize,
      backgroundPosition,
      backgroundColor
      
    } = this.props;


 
    const article_show = articles.slice(0, 2);

   return (



      <div className="case-studies-wrapper animate-in">
        <div className="number-item-wrapper">
          <NumberItem 
            number="03"
            color="#222"
            margin="40px 0px 0px 55px"
            />
          </div>
                    <div className="featured-title"><h3>Latest News</h3></div>

          <div className="cases-wrapper animate-in">
           {
                  article_show.map(obj =>
                  <div data-attr={obj.category} key={obj.id} style={{ backgroundImage: `url('${obj.image_main}')`, backgroundSize: obj.backgroundSize, backgroundPosition: obj.backgroundPosition,  backgroundColor: obj.backgroundColor}}>
                      <Link to={`/work/${obj.id}`} title={obj.name} aria-label={obj.exerpt}>
                          <span className="filter"></span>
                          <span className="init-filter"></span>
                          <span className="filter-hover"></span>
                          <span>
                            <p className="date">{obj.date}</p>
                            <h5>{obj.name}</h5>
                            {obj.collaboration ? <p className="collab">{obj.collaboration}</p> : ''}
                            <span><ReactMarkdown source={obj.exerpt}/></span>
                          </span>
                    
                    </Link>
                    </div>

                   
                  )
                }
          
          
        </div>
      </div>
        
    );
  }
}


