/*
 Contact Component
*/


import React, { Component } from 'react'; 
import {Helmet} from "react-helmet";
import TransitionHeader from './../TransitionHeader'; 

import ContactForm from './../ContactForm';
import FooterActionNavigation from './../FooterActionNavigation';
import octopusOne from '../../media/images/the-octopus.jpeg';
import octopusTwo from '../../media/images/octopus-blue.jpeg';

import './ContactComponent.scss';

export default class ContactComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }

  componentDidMount() {
    window.scrollTo(0, 0);

  }


  render(){

 


  return (
     <div className="contact-component-wrapper">

      <Helmet>
        <title>Start the conversation - Layercayk</title>
        <meta name="description" content="Start the conversation, every beautiful relationship starts with a simple hello."/>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://layercayk.com/contact" />
        <meta property="og:title" content="Contact. - Layercayk" />
        <meta property="og:description" content="Start the conversation, every beautiful relationship starts with a simple hello." />
        <meta property="og:image" content="/static/media/octopus-blue.920137de.jpeg" />
        <link rel="canonical" href="https://layercayk.com/contact" />
      </Helmet>

        <TransitionHeader
          title="Start the conversation"
          subtitle="Every beautiful relationship starts with a simple hello. "
          backgroundTitle="Contact"
          minySubHeading="Contact"
          minyleft="-64px"


        />
        <ContactForm
          intro={<span>Please get in touch through our email / phone number below or complete our contact form.</span>}
          form
          phone="1300 870 920"
          email="hello@layercayk.com"
          additional={<p style={{marginTop:'25px',paddingTop:'15px',borderTop:'1px solid rgba(0,0,0,0.1)'}}>Alternatively fill in our comprehensive brief <a href="/brief" style={{background: 'none'}} className="inline-link"><strong>here</strong></a> to kick off your project.</p>}
        />
   
        <FooterActionNavigation 
          backgroundone={octopusOne}
          backgroundtwo={octopusTwo}
          borderBottom="0px solid"
          linkone="/work"
          headingone="Work"
          headingonetext="Through creative solutions we take ideas and make them tangible."
          linktwo="/services"
          headingtwo="Services"
          headingtwotext="Crafting digital experiences to help brands grow."
        />
         
      </div>
    );
  }
}


