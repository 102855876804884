/*
 Home Component
*/


import React, { Component } from 'react';  
import {Helmet} from "react-helmet";
import HomeHeader from './../HomeHeader';
import BodyContent from './../BodyContent';
import CaseStudies from './../CaseStudies';
import BlogPostsHome from './../BlogPostsHome';
import ContactAction from './../ContactAction';
import Partners from './../Partners';
import Reviews from './../Reviews';
// lottie
import Lottie from 'react-lottie';


export default class HomeComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };


  }

  componentDidMount() {
    window.scrollTo(0, 0);

  //  luxy.init();

  }




  render(){

    const {

    } = this.props;

  

  return (
     <div className="home-component-wrapper" data-lax-preset="spin fadeInOut">
     <Helmet>
        <title>Layercayk - Digital solutions | No Office | Just Results.</title>
        <meta name="description" content="Create awareness through a positive design footprint. I create digital solutions for a digital world."/>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://layercayk.com" />
        <meta property="og:title" content="Layercayk - Digital solutions for a digital world." />
        <meta property="og:description" content="Create awareness through a positive Digital footprint." />
        <meta property="og:image" content="/static/media/octopus-blue.920137de.jpeg" />
        <link rel="canonical" href="https://layercayk.com" />
      </Helmet>
      <HomeHeader />
      <Partners />
      <BodyContent />
      <CaseStudies /> 
      {/*<BlogPostsHome />*/}
      <Reviews />
      <ContactAction />
      </div>
    );
  }
}


