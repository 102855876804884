/*
Reviews Component
*/

import React from 'react';
import './Reviews.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Star from './star.svg';
import Quote from './quote.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';

// Components
import NumberItem from '../NumberItem';

// Reviews data with corrected spelling
const reviews = [
  {
    name: 'Gopan Mondal',
    review: 'Mathew was so professional yet approachable. During our website design, we had to change a few times to get the best website, and Mathew never hesitated to update and re-work to make it the best website. Customer service at its best.'
  },
  {
    name: 'Scott Sandall',
    review: 'Thanks for the excellent website you did for me! Done at a very reasonable price compared to other agencies I spoke to. Would highly recommend. Thanks again!'
  },
  {
    name: 'Joseph Dunne',
    review: 'Throughout our project, Layercayk exceeded our expectations with their professionalism and the quality of their work. They delivered a high-end digital marketing project that significantly boosted our online presence. We\'re thrilled with the results and highly recommend Layercayk for their outstanding service and unbeatable pricing.'
  },
  {
    name: 'Mitch Cunningham',
    review: '10/10 would recommend..thank you Layercayk!'
  }
];

// Custom arrow components as functional React components
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={ArrowLeft} alt="Previous" /> {/* Use the arrow SVG */}
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={ArrowRight} alt="Next" /> {/* Use the arrow SVG */}
    </button>
  );
};

export default function Reviews() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
       {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  return (
    <section className="reviews animate-in">
    <NumberItem 
       number="04"
       position="absolute"
       margin="132px 0px 0px 50px"
      
       />
      <div className="heading-wrapper">
        <h4>What our customers say</h4>
      </div>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-slide"> {/* Add unique key */}
            <div className="stars" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Star} alt="Star" />
              <img src={Star} alt="Star" />
              <img src={Star} alt="Star" />
              <img src={Star} alt="Star" />
              <img src={Star} alt="Star" />
            </div>
            <p>{review.review}</p>
            <p><strong>{review.name}</strong></p>
            <img src={Quote} alt="Quote" className="quote"/>
          </div>
        ))}
      </Slider>
      <div className="review-link">
        <div className="star-review">
          <img src={Star} alt="Star" style={{width:'20px'}}/>
          <img src={Star} alt="Star" style={{width:'20px'}}/>
          <img src={Star} alt="Star" style={{width:'20px'}}/>
          <img src={Star} alt="Star" style={{width:'20px'}}/>
          <img src={Star} alt="Star" style={{width:'20px'}}/>
        </div>
        <div>
          <a href="https://www.google.com/maps/place/Layercayk/@-32.0336154,115.7882191,17z/data=!4m8!3m7!1s0x2a32a3caeeceb42b:0x735c3b2b8bc9a69b!8m2!3d-32.03362!4d115.79309!9m1!1b1!16s%2Fg%2F11w1gm06nv?entry=ttu" target="_blank" rel="noreferrer" aria-label="Write us a review" className="inline-link">Write us a review</a>
        </div>
      </div>
    </section>
  );
}
