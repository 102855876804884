/*
 Contact Component
*/

import React, { useEffect } from 'react'; 
import { Helmet } from "react-helmet";
import TransitionHeader from './../TransitionHeader'; 
import ContactForm from './../ContactForm';
import FooterActionNavigation from './../FooterActionNavigation';
import useBodyClass from '../../hooks/useBodyClass';

import './CareersComponent.scss';

const CareersComponent = () => {
  useBodyClass('careers-page');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="careers-wrapper">
      <Helmet>
        <title>We want to hear from you - Layercayk</title>
        <meta name="description" content="We are always looking for top talent from UI/UX designers, developers, and project managers." />
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://layercayk.com/contact" />
        <meta property="og:title" content="Contact. - Layercayk" />
        <meta property="og:description" content="We are always looking for top talent from UI/UX designers, developers, and project managers." />
        <meta property="og:image" content="/static/media/octopus-blue.920137de.jpeg" />
        <link rel="canonical" href="https://layercayk.com/contact" />
      </Helmet>

      <TransitionHeader
        title="We want to hear from you"
        subtitle="We're always looking out to find top digital talent."
        backgroundTitle="Careers"
        minySubHeading="Careers"
        minyleft="-64px"
      />

      <ContactForm
        intro={<span>We prioritize results over hours and understand that your best work may not fit into a traditional 8-hour day. With the freedom to choose a schedule that suits you, you can deliver your best outcomes while collaborating with an incredible team on exciting projects.<br/><br/>If you're ready to make an impact and thrive in a flexible, results-driven environment, we want to hear from you!<br/><br/>Send us your resume and portfolio via the email below. <br/><br/>We’re on the lookout for talented UI/UX Designers, Developers, and Project Managers—let’s create something amazing together!</span>}
        email="careers@layercayk.com"
      />

      <FooterActionNavigation 
        backgroundone="/path/to/octopusOne"
        backgroundtwo="/path/to/octopusTwo"
        borderBottom="0px solid"
        linkone="/work"
        headingone="Work"
        headingonetext="Through creative solutions we take ideas and make them tangible."
        linktwo="/services"
        headingtwo="Services"
        headingtwotext="Crafting digital experiences to help brands grow."
      />
    </div>
  );
};

export default CareersComponent;
